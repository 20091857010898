import React from "react";
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
import "./App.css";

function App() {
  const Map = ReactMapboxGl({
    accessToken:
      "pk.eyJ1Ijoic3BlZWRkcmFnb24iLCJhIjoiY2psejBmc2w3MGF4YTNwbmtrM2ZrbWp2dCJ9.SV5DQY91VkdVLUI_f1b-_w"
  });

  return (
    <div className="App">
      <div id="top-page">
        <a href="tel:965347032">
          <div className="contact_button">
            <img src="/images/artur.jpg" className="contact_button_image" />
            <div className="button_text">Contacto</div>
            <img src="/svg/phone.svg" className="button_phone" />
          </div>
          <img src="logo.svg" alt="AJ Principe Logo" id="logo" />
        </a>

        <div className="services">
          <div className="main-services">
            <a href="#manutencao">
              <img src="images/oil_change.jpg" />

              <div style={{ padding: "5px 10px 8px 10px" }}>Manutenção</div>
            </a>
          </div>
          <div>
            <img src="/svg/link.svg" className="link" />
          </div>
          <div className="main-services">
            <a href="#mecanica">
              <img src="images/engine.jpg" />

              <div style={{ padding: "5px 10px 8px 10px" }}>Mecânica</div>
            </a>
          </div>
          <div>
            <img src="/svg/link.svg" className="link" />
          </div>
          <div className="main-services">
            <a href="#eletronica">
              <img src="images/eletronic.jpg" />

              <div style={{ padding: "5px 10px 8px 10px" }}>Eletrónica</div>
            </a>
          </div>
        </div>
      </div>
      <div className="about-us-section">
        <div id="about-us">
          <div className="about-us-title">Sobre nós</div>
          <div className="about-us-body">
            <p>
              Somos uma oficina focada no cuidado do seu veículo. Temos mais de
              20 anos de experiência em lidar com automóveis, desde a sua
              manutenção até a resolução de diversos tipos de problemas,
              mecânicos ou elétricos.
            </p>
            <p>
              A nossa oficina está equipada com os equipamentos necessários para
              efectuar o correcto diagnóstico do seu veículo.
            </p>
          </div>
        </div>
        <div>
          <div className="workshop">
            <img src="/images/workshop.jpg" />
          </div>
        </div>
      </div>

      <div>
        <div className="header">
          <div
            className="header-text-body"
            style={{
              flex: "0.2"
            }}
          >
            <span className="header-title">Serviços</span>
          </div>
          <div className="triangle" />
          <div className="header-line" style={{ flex: "2" }}>
            &nbsp;
          </div>
        </div>
        <div>
          <div id="manutencao" className="section">
            <img src="/svg/car.svg" style={{ marginRight: "20px" }} />
            <span className="section_text">Manutenção</span>
          </div>
          <ul className="maintanence">
            <li>
              <div
                style={{ fontSize: "18px", fontWeight: 500, marginLeft: "5px" }}
              >
                Inspecção Periódica Obrigatória
              </div>
              <p>
                Deixe o seu automóvel ao nosso encargo para uma verificação
                completa dos componentes de segurança necessários para a
                inspeção periódica obrigatória. Verificamos desde a regulação
                das luzes, passando pela suspensão até aos gases de escape do
                motor do seu veículo.
              </p>
            </li>
            <li>
              <div
                style={{ fontSize: "18px", fontWeight: 500, marginLeft: "5px" }}
              >
                Manutenção do seu veículo
              </div>
              <p>
                Deixe ao nosso cuidado a manutenção programada do seu veículo.
                Efectuamos a troca do óleo do motor, do óleo da caixa de
                velocidades, verificação das velas, bem como a troca dos diversos filtros do seu veículo.
              </p>
            </li>
            <li>
              <div
                style={{ fontSize: "18px", fontWeight: 500, marginLeft: "5px" }}
              >
                Substituição de calços, discos de travão e suspensão
              </div>
              <p>
                Deixe ao nosso cuidado o seu veículo, para troca e/ou reparação
                dos seus discos de travão, bem como troca dos calços e suspensão
                aquando o fim de vida dos mesmos. Temos um serviço rápido para
                dar uma nova vida ao seu veículo e um conforto a sí.
              </p>
            </li>
          </ul>
          <div id="mecanica" className="section">
            <img src="/svg/wrench.svg" style={{ marginRight: "20px" }} />
            <span className="section_text">Mecânica</span>
          </div>
          <div className="mechanic-section">
            <div className="panel panel_red">
              <ul>
                <li>
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: 500,
                      marginLeft: "10px"
                    }}
                  >
                    Reparação do motor
                  </div>
                  <p>
                    Procedemos a troca de segmentos, cilindros, vielas, válvulas
                    e outros componentes do seu motor, para que volte a ganhar uma nova 
                    vida depois de ter ocorrido uma falha catastrófica no mesmo.
                  </p>
                </li>
                <li>
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: 500,
                      marginTop: "40px",
                      marginLeft: "10px"
                    }}
                  >
                    Reparação de bombas Injectoras
                  </div>
                  <p>
                    Os veículos a diesel de última geração utilizam bombas de
                    injeção de alta performance, que poderão ceder com o passar
                    do tempo. Conseguimos dar uma nova vida a estes componentes.
                  </p>
                </li>
              </ul>
            </div>
            <div className="panel panel_gray">
              <ul>
                <li>
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: 500,
                      marginLeft: "10px"
                    }}
                  >
                    Limpeza da EGR, filtro de particulas, etc.
                  </div>
                  <p>
                    Procedemos a limpeza destes componentes de modo a dar um
                    nova vida ao seu motor. Com o tempo este tipo de componentes
                    vão ganhando sujidade e por vezes é necessária uma limpeza
                    mais profunda.
                  </p>
                </li>
                <li>
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: 500,
                      marginTop: "40px",
                      marginLeft: "10px"
                    }}
                  >
                    Teste de compressão do motor, e outros diagnosticos.
                  </div>
                  <p>
                    Verificamos o estado do seu motor. Este teste é
                    principalmente útil se pretender saber qual o estado de
                    saude do mesmo na compra de um veículo usado.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div id="eletronica" className="section">
            <img src="/svg/chip.svg" style={{ marginRight: "20px" }} />
            <span className="section_text">Eletrónica</span>
          </div>
          <div
            style={{
              color: "#606060",
              lineHeight: "30px",
              width: "66%",
              textAlign: "left",
              margin: "auto",
              marginBottom: "100px"
            }}
          >
            <p>
              Desde há muito tempo os veículos deixaram de ser só componentes
              mecânicos, verificando-se cada vez mais a introdução de
              componentes eletrónicos cada vez mais avançados, e muitas vezes
              bastante complexos que requerem conhecimento especializado e
              máquinas especificas para trabalhar com eles.
            </p>

            <p>
              Ao longo de mais de 20 anos tornamo-nos especialistas na reparação
              deste tipo de componentes eletrónicos, fornecendo uma variada gama
              de serviços de reparação dos mesmos.
            </p>

            <p>
              Para mais informações relativamente a possibilidade de reparação e
              a valores, entre em contacto connosco.
            </p>
          </div>

          <div className="eletronic-section">
            <div className="vertical-section">
              <div>
                <img src="/images/dashboard.jpg" />
              </div>
              <div style={{ padding: "10px 30px" }}>
                <ul>
                  <li>Quadrante</li>
                  <li>Odómetro</li>
                  <li>Ecrã LCD</li>
                  <li>Ecrã LED</li>
                </ul>
              </div>
            </div>
            <div className="vertical-section">
              <div>
                <img src="/images/pcb.jpg" />
              </div>
              <div style={{ padding: "10px 30px" }}>
                <ul>
                  <li>Centralina</li>
                  <li>ABS</li>
                  <li>Airbag</li>
                  <li>Rádio</li>
                </ul>
              </div>
            </div>
            <div className="vertical-section">
              <div>
                <img src="/images/wires.jpg" />
              </div>
              <div style={{ padding: "10px 30px" }}>
                <ul>
                  <li>Imobilizador</li>
                  <li>Chave</li>
                  <li>Alarme</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="header">
          <div className="header-text-body">
            <span className="header-title">Contacto</span>
          </div>
          <div className="triangle" />
          <div
            className="header-line"
            style={{
              flex: "2"
            }}
          >
            &nbsp;
          </div>
        </div>

        <div className="contact-section">
          <div style={{ flex: 1 }}>
            Poderá entrar em contacto com nosco através das seguintes formas:
          </div>
          <div
            style={{
              flex: 1,
              textAlign: "left",
              padding: "30px",
              textAlign: "-webkit-center"
            }}
          >
            <div style={{ textAlign: "left", display: "table-cell" }}>
              <div className="contact-item">
                <span>
                  <img src="svg/mail_gray.svg" />
                </span>
                aj.principe@sapo.pt
              </div>
              <div className="contact-item">
                <span>
                  <img src="svg/phone_gray.svg" />
                </span>
                <a href="tel:227453786">227453786</a>
              </div>
              <div className="contact-item">
                <span>
                  <img src="svg/mobile_phone_gray.svg" />
                </span>
                <a href="tel:965347032">965347032</a>
              </div>
              <div className="contact-item">
                <span>
                  <img src="svg/facebook_gray.svg" />
                </span>
                <a href="https://www.facebook.com/oficina.ajprincipe/">
                  Oficina.ajprincipe
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="header">
          <div className="header-text-body">
            <span className="header-title">Localização</span>
            <span className="header-subtitle">das nossas instalações</span>
          </div>
          <div className="triangle" />
          <div
            className="header-line"
            style={{
              flex: "2"
            }}
          >
            &nbsp;
          </div>
        </div>

        <div className="map-section">
          <Map
            style="mapbox://styles/mapbox/streets-v9"
            center={[-8.52897, 41.000837]}
            zoom={[15]}
            className="map-visual"
          >
            <Layer
              type="symbol"
              id="marker"
              layout={{ "icon-image": "marker-15" }}
            >
              <Feature coordinates={[-8.52897, 41.000837]} />
            </Layer>
          </Map>

          <div
            style={{
              flex: 1
            }}
          >
            <div className="map-address">
              <p>
                Rua das Fragas 204
                <br />
                Zona Industrial de Sanguedo
                <br />
                Sanguedo
              </p>
            </div>
            <div style={{ display: "flex", flex: 1, height: "60%" }}>
              <div style={{ flex: 1 }}>
                <div>
                  <img src="/svg/schedule.svg" />
                </div>
                <div className="map-schedule-days">
                  <div>Segunda a Sexta</div>
                  <div>Sabado</div>
                  <div>Domingo e Feriados</div>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <img src="/svg/clock.svg" />
                </div>
                <div className="map-schedule-time">
                  <div>8:30 - 12:30 e 14:00 - 18:30</div>
                  <div>8:30 - 12:30</div>
                  <div>Fechados</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="header">
          <div className="header-text-body">
            <span className="header-title">Marcas</span>
            <span className="header-subtitle">com as quais trabalhamos</span>
          </div>
          <div className="triangle" />
          <div className="header-line" style={{ flex: "2" }}>
            &nbsp;
          </div>
        </div>

        <div className="manufacturers">
          <div style={{ flex: 1 }}>
            <img src="/manufacturers/shell.svg" style={{ width: "200px" }} />
            <br />
            <img
              src="/manufacturers/textar.svg"
              style={{ width: "200px", marginTop: "60px" }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <img
              src="/manufacturers/bosch-service-1.svg"
              style={{ width: "200px" }}
            />
            <br />
            <img
              src="/manufacturers/kyb-gas-shocks.svg"
              style={{ width: "200px", marginTop: "60px" }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <img src="/manufacturers/monroe.svg" style={{ width: "200px" }} />
            <br />
            <img
              src="/manufacturers/sachs-1.svg"
              style={{ width: "200px", marginTop: "60px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
